.default-layout{
    display: grid;
    grid-template-columns: 250px auto;
    grid-auto-flow: column;
    background: var(--default-page-background);
    @media only screen and (max-width: 1455px) {
        grid-auto-flow: row;
        grid-template-columns: auto;
    }
    main{
        padding: 0 56px;
    }
}