.sidebar{
    width: 250px;
    height: 100vh;
    background: var(--default-sidebar-background);
    position: sticky;
    left: 0;
    top: 0;
    .icon-container{
        height: 80px;
        width: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
    }
    ul{
        color: var(--default-color-neutral-03);
        list-style: none;
        padding-inline-start:16px;
        li{
            padding: 16px 0;
            a{
                color: inherit;
                text-decoration: none;
                font-weight: 600;
                font-size: 16px;
                &:hover{
                    color: var(--default-color-neutral-01);
                }
            }
        }
    }
}
.active{ 
    color: var(--default-color-neutral-01); 
}