.main-info{
    display: flex;
    p{
        margin: 0;
        padding: 8px 13px;
    }
}

.charts-container{
    display: grid;
    justify-content: space-evenly;
    align-items: center;
    grid-auto-flow: column;
    width: 100%;
    column-gap: 16px;
    @media only screen and (max-width: 1455px) {
        grid-auto-flow: row;
        row-gap: 25px;
        margin: 25px 0;
    }
    .chart{
        display: grid;
        justify-content: center;
        align-items: center;
        width: 531px;
        height: 330px;
        color: var(--default-color-neutral-03);
        background: var(--default-sidebar-background);
        box-shadow: 4px 4px 32px rgba(140, 140, 140, 0.25);
    }
}